import React from 'react'
import {Link} from 'react-router-dom'
import Logo from '../Img/LogoFooter.svg'
import '../css/Components/footer.css'

function Footer() {
  return (
    <div className='footer'>
    <div className='container'>
      <div className="row">

        <div className="col-6 col-lg-3 d-flex flex-column justify-content-lg-center align-items-start">
            <p className='mb-0 text-left'>Contacto</p>
            <p className='mb-0 text-left'>Tel: 4793-2014</p>
            <p className='mb-0 text-left'>Mail: decomprasempresa10@gmail.com</p>
            <p className='mb-0 text-left'>Lunes a Sábados</p>
            <p className='mb-0 text-left'>09:00 hs - 20:00 hs</p>
        </div>

        <div className="d-none d-lg-block col-lg-1"></div>

        <div className="d-none d-lg-flex col-lg-4 d-flex justify-content-center align-items-center">
            <img src={Logo} alt="Logo" />
        </div>

        <div className="col-6 col-lg-4 d-flex justify-content-sm-end">

            <div className='me-3 me-lg-5'>
                <p className='mb-0'>Ropa interior</p>
                <p className='mb-0'>Decoración</p>
            </div>

            <div>
              <p className='mb-0'>Home & Decoración</p>
              <p className='mb-0'>Doufour</p>
              <p className='mb-0'>Cocot</p>
              <p className='mb-0'>Tecnología</p>
              <p className='mb-0'>Pijamas</p>
              <p className='mb-0'>Medias</p>
              <Link className='text-decoration-none' to={`/administrar`} style={{color:'transparent'}}>Administrar</Link>
            </div>

        </div>

      </div>
    </div>
    </div>
  )
}

export default Footer
