import React,{useState,useEffect} from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import DestacadosCategoria from '../Components/DestacadosCategoria';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import ListaLenceria from '../Components/ListaLenceria';
import ProductosData from '../Components/ProductosData';
import '../css/Pages/home.css';

function Productos({ lenceria, onCategorySelect, categoriaActual }) {

  const navigate = useNavigate();
  const [paginaActual, setPaginaActual] = useState(1);
  const [visiblePages, setVisiblePages] = useState([1, 2, 3]);

  const handleCategoryClick = (categoria) => {
    onCategorySelect(categoria);
    navigate(`/${categoria}`);
  };

  useEffect(() => {
    setPaginaActual(1);
    setVisiblePages([1, 2, 3]);
  }, [categoriaActual]);

  useEffect(() => {
    setPaginaActual(1);
  }, [categoriaActual]);

  return (
    <div className='d-flex flex-column justify-content-between' style={{ height: '100vh' }}>
      <Helmet>
        <title>Página de productos</title>
      </Helmet>

      <Header onCategorySelect={onCategorySelect} handleCategoryClick={handleCategoryClick} />

        <ProductosData lenceria={lenceria} subcategoria={categoriaActual} />      

      <div className='container mt-5'>

        <DestacadosCategoria lenceria={lenceria} categoria={categoriaActual} />

        <ListaLenceria lenceria={lenceria} categoria={categoriaActual} paginaActual={paginaActual} setPaginaActual={setPaginaActual} visiblePages={visiblePages} setVisiblePages={setVisiblePages}/>

      </div>

      <Footer />
    </div>
  )
}

export default Productos 