import React, { useState, useEffect } from 'react';
import '../css/Components/location.css';
import Local from '../Img/Local.jpg'

function Location() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const iframeStyle = {
    border: 0
  };

  return (
    <div className='location mt-5'>

      <h3 className='location mb-0'>DONDE ENCONTRARNOS</h3>
      <p className='location mb-4'>Retira tus productos en nuestro local</p>
      <div className='d-flex flex-column align-items-start h-100'>

        <div className='d-flex flex-column-reverse flex-md-row w-100'>

          <div style={{width: windowWidth < 768 ? '100%' : '150%' }}>
            <iframe
              title="Ubicación del Local en Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1955.3848060198125!2d-58.500696992394715!3d-34.48888382145969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb1a8f403bf4f%3A0x15892c6ecce87159!2sGral.%20Alvear%20138%2C%20B1640EVD%20Mart%C3%ADnez%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1703350503819!5m2!1ses!2sar"
              width="100%"
              height="350"
              style={iframeStyle}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </div>

          <div className='text-center d-flex flex-column align-items-center justify-content-center mb-5 mb-md-0 w-100'>
            <p className='mb-0 titlecontact'>Contacto</p>
            <p className='mb-0 textcontact'>teléfono: 4793-2014</p>
            <p className='mb-0 textcontact'>Lunes a sábados</p>
            <p className='mb-0 textcontact'>De 09:00hs - 20:00hs</p>
          </div>

        </div>

        <div className='w-100'>
          <img src={Local} className='mt-5' style={{ height: "450px", width: windowWidth < 768 ? '100%' : '60%' }} alt="Local" title='Local' />
        </div>

      </div>
    </div >
  );
}

export default Location;
