import React from 'react'
import '../css/Components/productosdata.css'

function ProductosData({ subcategoria }) {

    let categoriaPrincipal;

    switch (subcategoria) {
        case 'Corpiños':
        case 'Bombachas':
        case 'Ropa deportiva':
        case 'Trajes de baño':
        case 'Body y portaligas':
        case 'Linea modeladora':
            categoriaPrincipal = 'Mujeres';
            break;
        case 'Boxer':
        case 'Medias':
        case 'Pijamas hombre':
        case 'Ropa térmica':
            categoriaPrincipal = 'Hombres';
            break;
        case 'Textiles':
        case 'Decoración':
        case 'Muebles':
        case 'Deportes':
            categoriaPrincipal = 'Hogar y vida'
            break;
        case "Accesorios":
        case "Tecnología":
        case "Belleza":
        case "Oficina y Libreria":
        case "Mascotas":
        case "Gangas":
        case "Vuelta al cole":
            categoriaPrincipal = 'Otros'
            break;
        default:
            categoriaPrincipal = null
    }

    return (
        subcategoria !== 'Talles' && (
            <div className='productosdata'>
                <div className='container d-flex justify-content-between align-items-center py-4 px-4'>
                    <p className='category mb-0'>{"Categorias >"}{categoriaPrincipal && <span className='category'>{` ${categoriaPrincipal} >`}</span>}{subcategoria && <span className='categorys'>{` ${subcategoria}`}</span>}</p>
                    <p className='titleproducts mb-0'>{subcategoria}</p>
                    <p className='d-none d-md-block inv mb-0'>{`Categorias ${categoriaPrincipal ? `> ${categoriaPrincipal} ` : ''}${subcategoria ? `> ${subcategoria}` : ''}`}</p>
                </div>
            </div>
        )
    )
}

export default ProductosData
