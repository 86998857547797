import React from 'react'
import { Link } from 'react-router-dom'
import '../css/Components/banner.css'
import Chica from '../Img/Chica.svg'
import Chica2 from '../Img/Chica2.svg'
import Chico from '../Img/Chico.svg'
import Chico2 from '../Img/Chico2.svg'
import Cocot from '../Img/Cocot.svg'
import Dufour from '../Img/Dufour.svg'
import Homedeco from '../Img/HomeDeco.svg'

function Banner({ handleCategoryClick }) {
    return (
        <div className='container banner'>
            <div className="row">

                <div className="col-md-5 d-flex flex-column px-1">

                    <div className='banner1 d-flex justify-content-end'>

                        <img className='chicas chica' src={Chica} alt="chica" />
                        <img className='chicas chica2' src={Chica2} alt="chica" />

                        <div className='d-flex flex-column justify-content-center align-items-end pe-md-3'>
                            <img className='cocot' src={Cocot} alt="cocot" />
                            <Link className='vermas text-decoration-none align-self-center' onClick={() => handleCategoryClick('Mujeres')} to={'/mujeres'} >Ver más</Link>
                        </div>

                    </div>

                </div>

                <div className="col-md-3 d-flex flex-column chicos px-1">

                    <div className='banner2 d-flex justify-content-start h-100'>

                        <div className='d-flex flex-column justify-content-center align-items-start ps-md-3'>
                            <img className='dufour' src={Dufour} alt="Dufour" style={{ zIndex: '10' }} />
                            <Link className='vermas text-decoration-none align-self-center' onClick={() => handleCategoryClick('Hombres')} to='/hombres'>Ver más</Link>
                        </div>

                        <img className='chicos chico d-md-none' src={Chico} alt="chico" />
                        <img className='chicos chico2' src={Chico2} alt="chico" />
                    </div>

                </div>

                <div className="col-md-4 d-flex flex-column chicos px-1">

                    <div className='banner3 d-flex justify-content-start h-100 w-100'>

                        <div className='d-flex flex-column justify-content-center align-items-start ps-md-4'>
                            <p className='homedecop mb-0'>Hogar & Deco</p>
                            <Link className='vermas text-decoration-none align-self-center align-self-md-start' onClick={() => handleCategoryClick('Hogar y deco')} to='/Hogar y deco'>Ver más</Link>
                        </div>
                        <img className='homedeco' src={Homedeco} alt="Home y Deco" />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Banner
