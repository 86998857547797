import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import React, { useEffect, useState } from 'react';
import { Route, HashRouter as Router, Routes, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import Productos from './Pages/Productos';
import ShowPrendas from './Prendas/ShowPrendas'
import CreatePrenda from './Prendas/CreatePrenda'
import EditPrenda from './Prendas/EditPrenda'

const URI = 'https://somosdecompras.onrender.com/prendas'
const adminPassword = 'Vitto0817!';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
  const [categoriaActual, setCategoriaActual] = useState('');
  const [lenceria, setLenceria] = useState([])
  const [password, setPassword] = useState('Vitto0817!');


  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    getPrenda()
  }, [])

  const getPrenda = async () => {
    try {
      const res = await axios.get(URI);
      setLenceria(res.data);
    } catch (error) {
      console.error("Error al obtener piezas:", error);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
};

const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (password === adminPassword) {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
    } else {
        alert('Contraseña incorrecta');
    }
};

  return (
    <Router>
        <Routes>
        <Route path="/" element={<Home lenceria={lenceria} categoriaActual={categoriaActual} onCategorySelect={setCategoriaActual} />} />
        <Route path={`/${categoriaActual}`} element={<Productos lenceria={lenceria} categoriaActual={categoriaActual} onCategorySelect={setCategoriaActual} />} />
        <Route path="/administrar" element={<ShowPrendas handleLoginSubmit={handleLoginSubmit} handlePasswordChange={handlePasswordChange} lenceria={lenceria} setLenceria={setLenceria} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>} />
        <Route path="/create" element={<CreatePrenda handleLoginSubmit={handleLoginSubmit} handlePasswordChange={handlePasswordChange} isAuthenticated={isAuthenticated}/>} />
        <Route path="/edit/:id" element={<EditPrenda handleLoginSubmit={handleLoginSubmit} handlePasswordChange={handlePasswordChange} isAuthenticated={isAuthenticated}/>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;