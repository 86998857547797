import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Img/Logo.svg';
import '../css/Components/header.css';

function Header({ handleCategoryClick }) {

  return (
    <>
      <nav class="boxshadow navbar navbar-expand-lg bg-light py-3">
        <div class="container px-4 px-md-0">
          <Link className='Logo' to={'/'}>
            <img className='me-4 w-100' src={Logo} alt="Logo" />
          </Link>
          <button class="navbar-toggler navbar-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="d-lg-flex justify-content-lg-end collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
              <li class="nav-item py-2 py-lg-0">
                <Link class="nav-link" onClick={() => handleCategoryClick('Talles')} to={`/talles`}>Talles</Link>
              </li>
              <li class="nav-item dropdown py-2 py-lg-0">
                <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Mujeres
                </button>
                <ul class="dropdown-menu">
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Corpiños')} to={`/corpiños`}>Corpiños</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Bombachas')} to={`/bombachas`}>Bombachas</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Pijamas mujer')} to={`/pijamas mujer`}>Pijamas</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Ropa deportiva mujer')} to={`/ropa deportiva mujer`}>Ropa deportiva</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Ropa térmica mujer')} to={`/ropa térmica mujer`}>Ropa térmica</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Trajes de baño mujer')} to={`/trajes de baño mujer`}>Trajes de baño</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Body y portaligas')} to={`/body y portaligas`}>Body y portaligas</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Linea modeladora')} to={`/linea modeladora`}>Línea modeladora</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Medias mujer')} to={`/medias mujer`}>Medias</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Mujeres')} to={`/mujeres`}>Todo mujeres</Link></li>
                </ul>
              </li>
              <li class="nav-item dropdown py-2 py-lg-0">
                <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Niños
                </button>
                <ul class="dropdown-menu">
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Pijamas niños')} to={`/pijamas niños`}>Pijamas</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Medias niños')} to={`/medias niños`}>Medias</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Trajes de baño niños')} to={`/trajes de baño niños`}>Trajes de baño</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Ropa interior niños')} to={`/ropa interior niños`}>Ropa interior</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Bebés y maternidad')} to={`/bebés y maternidad`}>Bebés y maternidad</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Todo niños')} to={`/todo niños`}>Todo niños</Link></li>
                </ul>
              </li>
              <li class="nav-item dropdown py-2 py-lg-0">
                <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Hombres
                </button>
                <ul class="dropdown-menu">
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Boxer')} to={`/boxer`}>Boxer</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Slip')} to={`/slip`}>Slip</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Medias hombre')} to={`/medias hombre`}>Medias</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Pijamas hombre')} to={`/pijamas hombre`}>Pijamas</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Ropa térmica hombre')} to={`/ropa térmica hombre`}>Ropa Térmica</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Hombres')} to={`/hombres`}>Todo Hombres</Link></li>
                </ul>
              </li>
              <li class="nav-item dropdown py-2 py-lg-0">
                <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Hogar y deco
                </button>
                <ul class="dropdown-menu">
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Textiles')} to={`/textiles`}>Textiles hogar</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Decoración')} to={`/decoración`}>Decoración</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Muebles')} to={`/muebles`}>Muebles</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Hogar y deco')} to={`/hogar y deco`}>Todo hogar y deco</Link></li>
                </ul>
              </li>

              <li class="nav-item dropdown py-2 py-lg-0">
                <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Otros
                </button>
                <ul class="dropdown-menu">
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Accesorios')} to={`/accesorios`}>Accesorios</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Tecnología')} to={`/tecnología`}>Tecnología</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Belleza')} to={`/belleza`}>Belleza</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Oficina y libreria')} to={`/oficina y libreria`}>Oficina y Libreria</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Mascotas')} to={`/mascotas`}>Mascotas</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Vuelta al cole')} to={`/vuelta al cole`}>Vuelta al cole</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Navidad')} to={`/Navidad`}>Navidad</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Gangas')} to={`/gangas`}>Gangas</Link></li>
                  <li><Link class="dropdown-item px-2" onClick={() => handleCategoryClick('Otros')} to={`/otros`}>Todo otros</Link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  )
}

export default Header;
