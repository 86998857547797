import React, { useState, useEffect } from 'react';
import '../css/Components/listalenceria.css';
import ML from '../Img/ML.svg';
import MLgrey from '../Img/MLgrey.svg';
import HombresTalles from '../Img/Talles/Hombres.png';
import MujeresTalles from '../Img/Talles/Mujeres.png';
import NiñasTalles from '../Img/Talles/Niñas.png';
import NiñosTalles from '../Img/Talles/Niños.png';

function ListaLenceria({ lenceria, categoria, paginaActual, setPaginaActual, visiblePages, setVisiblePages }) {
  const [isLoading, setIsLoading] = useState(true);
  const productosPorPagina = 15;
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    if (lenceria && lenceria.length > 0) {
      setIsLoading(false);
    }
  }, [lenceria]);

  const updateVisiblePages = (direction) => {
    const maxPage = Math.ceil(lenceriaFiltrada.length / productosPorPagina);
    let newVisiblePages = visiblePages.map(page => page + direction);

    if (newVisiblePages[0] < 1) {
      newVisiblePages = [1, 2, 3];
    } else if (newVisiblePages[newVisiblePages.length - 1] > maxPage) {
      newVisiblePages = [maxPage - 2, maxPage - 1, maxPage].filter(page => page > 0);
    }
    setVisiblePages(newVisiblePages);
  };

  const handleMouseOver = (index) => setHoveredIndex(index);
  const handleMouseOut = () => setHoveredIndex(null);

  const lenceriaFiltrada = categoria ? lenceria.filter((item) => {
    return categoria === 'Hombres' || categoria === 'Mujeres' || categoria === 'Bebés y maternidad' || categoria === 'Hogar y deco' || categoria === 'Otros' ? item.general === categoria : item.categoria === categoria;
  }) : [];

  const indiceUltimoProducto = paginaActual * productosPorPagina;
  const indicePrimerProducto = indiceUltimoProducto - productosPorPagina;
  const productosActuales = lenceriaFiltrada.slice(indicePrimerProducto, indiceUltimoProducto);

  const paginar = numeroPagina => setPaginaActual(numeroPagina);

  const paginasTotales = Math.ceil(lenceriaFiltrada.length / productosPorPagina);

  const hayProductos = lenceriaFiltrada.length > 0;
  const mostrarMensajeNoDisponible = categoria !== 'Talles' && !hayProductos;

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className='d-flex flex-wrap justify-content-center listalenceria'>
          {categoria && hayProductos ? (
            productosActuales.map((item, index) => (
              <a href={item.mercadolibre} title='Ir a Mercado Libre' onMouseOver={() => handleMouseOver(index)} onMouseOut={handleMouseOut} target="_blank" rel="noopener noreferrer" className='text-decoration-none d-flex flex-column justify-content-start' key={index}>
                <div className='imgdatalista'>
                  <img className='d-flex align-self-center p-0' src={item.src} alt={item.nombre} />
                </div>
                <div className='datalista d-flex justify-content-center'>

                  <div className='firstlistalenceriadata d-flex flex-column justify-content-around'>
                    <h3 className='mb-0 mt-1 listalenceria px-2' style={{ marginTop: '15px' }}>{item.nombre}</h3>
                    <div className='d-flex align-items-center px-2 pb-2'>
                      {item.colores && item.colores.split(',').map(color => color.trim())
                        .slice(0, 6)
                        .map((color, colorIndex) => (
                          <div key={colorIndex} className={`color-div mx-1 ${color.replace(/[\s/]+/g, '')}`}></div>
                        ))}
                    </div>
                  </div>

                  <div className='secondlistalenceriadata d-none d-md-flex flex-column align-items-center justify-content-start'>
                    <img className='ML' src={hoveredIndex === index ? ML : MLgrey} alt="mercado libre icon" />
                    <p className='talles mb-2 mt-2 mt-md-0'>{['Hombres', 'Mujeres', 'Niños'].includes(item.general) ? 'Talles Disponibles' : 'Medidas'}</p>
                    <p className='talles mb-0'>{item.talles}</p>
                  </div>

                </div>
              </a>
            ))
          ) : mostrarMensajeNoDisponible ? (
            <p>No hay productos disponibles de esta categoría por el momento.</p>
          ) : (
            null
          )}

          {categoria === 'Talles' && (
            <div className='d-flex justify-content-center align-items-center flex-wrap' style={{ width: '80%' }}>
              <img className='mujertalle' src={MujeresTalles} style={{ marginBottom: '30px' }} alt="Talles de mujeres" title='Talles de mujeres' />
              <img className='otrostalle' src={HombresTalles} style={{ marginBottom: '40px' }} alt="Talles de hombres" title='Talles de hombres' />
              <img className='otrostalle' src={NiñasTalles} style={{ marginBottom: '40px' }} alt="Talles de niñas" title='Talles de niñas' />
              <img className='otrostalle' src={NiñosTalles} style={{ marginBottom: '40px' }} alt="Talles de niños" title='Talles de niños' />
            </div>
          )}

        </div>
      )}
      {categoria && hayProductos && lenceriaFiltrada.length > productosPorPagina && (
        <div className='pagination d-flex justify-content-center mt-5 mb-5'>
          <button
            className="pagination-arrow"
            onClick={() => updateVisiblePages(-1)}
            disabled={visiblePages[0] === 1}
          >←</button>

          {visiblePages.map(numero => (
            <button
              className={`pagination px-2 mx-2 ${paginaActual === numero ? 'paginations' : ''}`}
              onClick={() => paginar(numero)}
              key={numero}
              style={{ display: numero <= paginasTotales ? 'inline-block' : 'none' }}
            >
              {numero}
            </button>
          ))}

          <button
            className="pagination-arrow"
            onClick={() => updateVisiblePages(1)}
            disabled={visiblePages[visiblePages.length - 1] === paginasTotales}
          >→</button>
        </div>
      )}
    </>
  );
}

export default ListaLenceria;