import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const URI = 'https://somosdecompras.onrender.com/prendas'

const CreatePrenda = ({ isAuthenticated, handleLoginSubmit, handlePasswordChange }) => {
    const [codigo, setCodigo] = useState('')
    const [nombre, setNombre] = useState('')
    const [general, setGeneral] = useState('')
    const [categoria, setCategoria] = useState('')
    const [bestsellers, setBestSellers] = useState('')
    const [destacadoscategoria, setDestacadosCategoria] = useState('')
    const [colores, setColores] = useState('')
    const [talles, setTalles] = useState('')
    const [mercadolibre, setMercadoLibre] = useState('')
    const [src, setSrc] = useState('')
    const navigate = useNavigate()

    const store = async (e) => {
        e.preventDefault()
        await axios.post(URI, {
            codigo: codigo,
            nombre: nombre,
            general: general,
            categoria: categoria,
            bestsellers: bestsellers,
            destacadoscategoria: destacadoscategoria,
            colores: colores,
            talles: talles,
            mercadolibre: mercadolibre,
            src: src
        })
        navigate('/administrar')
    }



    if (!isAuthenticated) {
        navigate('/administrar')
    }
    if (isAuthenticated) {

        return (
            <div className='container'>
                <div className='row'>
                    <div className='col'>

                        <h1>Crear Producto</h1>
                        <form onSubmit={store}>

                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Código</p>
                                <input className='form-control' value={codigo} onChange={(e) => setCodigo(e.target.value)} type='text' placeholder='Código'></input>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Nombre</p>
                                <input className='form-control' value={nombre} onChange={(e) => setNombre(e.target.value)} type='text' placeholder='Medias laycra..'></input>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>General</p>
                                <select required className='w-100 form-control' value={general} onChange={(e) => setGeneral(e.target.value)}>
                                    <option value="">Seleccione el general</option>
                                    <option value="Mujeres">Mujeres</option>
                                    <option value="Niños">Niños</option>
                                    <option value="Hombres">Hombres</option>
                                    <option value="Hogar y deco">Hogar y deco</option>
                                    <option value="Otros">Otros</option>
                                </select>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Categoria</p>
                                <select required className='w-100 form-control' value={categoria} onChange={(e) => setCategoria(e.target.value)}>
                                    <option value="">Seleccione una categoría</option>
                                    <option value="Corpiños">Corpiños</option>
                                    <option value="Bombachas">Bombachas</option>
                                    <option value="Pijamas hombre">Pijamas hombre</option>
                                    <option value="Pijamas niños">Pijamas niños</option>
                                    <option value="Pijamas mujer">Pijamas mujer</option>
                                    <option value="Ropa deportiva mujer">Ropa deportiva mujer</option>
                                    <option value="Ropa deportiva hombre">Ropa deportiva hombre</option>
                                    <option value="Trajes de baño mujer">Trajes de baño mujer</option>
                                    <option value="Trajes de baño niños">Trajes de baño niños</option>
                                    <option value="Body y portaliga">Body y portaliga</option>
                                    <option value="Linea modeladora">Linea modeladora</option>
                                    <option value="Mujer">Mujer</option>
                                    <option value="Niños">Niños</option>
                                    <option value="Bebés y maternidad">Bebés y maternidad</option>
                                    <option value="Boxer">Boxer</option>
                                    <option value="Slip">Slip</option>
                                    <option value="Medias hombre">Medias hombre</option>
                                    <option value="Medias mujer">Medias mujer</option>
                                    <option value="Medias niños">Medias niños</option>
                                    <option value="Pijamas mujer">Pijamas mujer</option>
                                    <option value="Pijamas niños">Pijamas niños</option>
                                    <option value="Pijamas hombre">Pijamas hombre</option>
                                    <option value="Ropa térmica hombre">Ropa térmica hombre</option>
                                    <option value="Ropa térmica mujer">Ropa térmica mujer</option>
                                    <option value="Hombre">Hombre</option>
                                    <option value="Textiles">Textiles</option>
                                    <option value="Decoración">Decoración</option>
                                    <option value="Muebles">Muebles</option>
                                    <option value="Deportes">Deportes</option>
                                    <option value="Accesorios">Accesorios</option>
                                    <option value="Tecnología">Tecnología</option>
                                    <option value="Belleza">Belleza</option>
                                    <option value="Oficina y libreria">Oficina y librería</option>
                                    <option value="Mascotas">Mascotas</option>
                                    <option value="Gangas">Gangas</option>
                                    <option value="Vuelta al cole">Vuelta al cole</option>
                                </select>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Best Seller</p>
                                <input className='form-control' value={bestsellers} onChange={(e) => setBestSellers(e.target.value)} type='text' placeholder='0 para no 1 para si'></input>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Destacados Categoria</p>
                                <input className='form-control' value={destacadoscategoria} onChange={(e) => setDestacadosCategoria(e.target.value)} type='text' placeholder='0 para no 1 para si'></input>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Colores</p>
                                <input className='form-control' value={colores} onChange={(e) => setColores(e.target.value)} type='text' placeholder='AZUL,ROJO,VERDE'></input>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Talles</p>
                                <input className='form-control' value={talles} onChange={(e) => setTalles(e.target.value)} type='text' placeholder='1/s - 2/m - 3/l - 4/xl - 5/xxl'></input>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Link de mercado libre</p>
                                <input className='form-control' value={mercadolibre} onChange={(e) => setMercadoLibre(e.target.value)} type='text' placeholder='Link..'></input>
                            </div>
                            <div className='mb-3'>
                                <p className='titlecreate mb-0'>Link de Imagen</p>
                                <input className='form-control' value={src} onChange={(e) => setSrc(e.target.value)} type='text' placeholder='Pegar dirección de imágen' />
                            </div>

                            <button type='submit btn btn-primary'>Subir</button>

                        </form>


                    </div>
                </div>

            </div>
        )
    }
}

export default CreatePrenda