import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const URI = 'https://somosdecompras.onrender.com/prendas';


const ShowPrendas = ({ lenceria, setLenceria, isAuthenticated, setIsAuthenticated, handleLoginSubmit, handlePasswordChange }) => {
    const [categoriaFiltrada, setCategoriaFiltrada] = useState('');
    const [busquedaCodigo, setBusquedaCodigo] = useState('');

    const getPrenda = useCallback(async () => {
        try {
            const res = await axios.get(URI);
            setLenceria(res.data);
        } catch (error) {
            console.error("Error al obtener prendas:", error);
        }
    }, [setLenceria]);

    useEffect(() => {
        getPrenda();
    }, [getPrenda]);




    const deletePrenda = async (id) => {
        const isConfirmed = window.confirm("¿Estás seguro de que quieres eliminar esta prenda?");
        if (isConfirmed) {
            try {
                await axios.delete(`${URI}/${id}`);
                getPrenda();
            } catch (error) {
                console.error("Error al eliminar prenda:", error);
            }
        }
    };

    const handleCategoriaChange = (e) => {
        setCategoriaFiltrada(e.target.value);
    };

    const handleCodigoChange = (e) => {
        setBusquedaCodigo(e.target.value);
    };

    const categoriasUnicas = [...new Set(lenceria.map(item => item.categoria))];

    if (!isAuthenticated) {
        return (
            <div className="container">
                <form onSubmit={handleLoginSubmit}>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Contraseña</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Ingresar</button>
                </form>
            </div>
        );
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col d-flex flex-column'>
                    <input
                        type="text"
                        placeholder="Buscar por código"
                        value={busquedaCodigo}
                        onChange={handleCodigoChange}
                        className='mt-3 mb-3'
                    />
                    <select onChange={handleCategoriaChange} value={categoriaFiltrada} className='mb-3'>
                        <option value=''>Todas las categorías</option>
                        {categoriasUnicas.map((categoria, index) => (
                            <option key={index} value={categoria}>{categoria}</option>
                        ))}
                    </select>
                    <Link to={'/create'} className='h1 text-decoration-none text-center'>Crear producto</Link>
                    {lenceria
                        .filter(prenda => (categoriaFiltrada === '' || prenda.categoria === categoriaFiltrada) &&
                            (busquedaCodigo === '' || prenda.codigo.includes(busquedaCodigo)))
                        .map((prenda) => (
                            <ul className='d-flex flex-column flex-sm-row justify-content-around align-items-center list-unstyled' key={prenda.id}>
                                <li>{prenda.codigo}</li>
                                <li>{prenda.categoria}</li>
                                <li>{prenda.nombre}</li>
                                <li><img style={{ width: 100, height: 200 }} src={prenda.src} alt='prenda' /></li>
                                <li>
                                    <Link to={`/edit/${prenda.id}`} className='btn btn-info'>Editar</Link>
                                    <button onClick={() => deletePrenda(prenda.id)} className='btn btn-danger'>Eliminar</button>
                                </li>
                            </ul>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default ShowPrendas;
