import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/Components/destacados.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
}

function DestacadosCategoria({ lenceria,categoria }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const itemsDestacados = lenceria.filter(item => item.destacadoscategoria === 1 && item.categoria === categoria);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: windowWidth < 400 ? 2 : windowWidth < 600 ? 3 : 4,
    slidesToScroll: windowWidth < 400 ? 2 : windowWidth < 600 ? 3 : 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <>
    {!itemsDestacados&&(
    <div className='container containerdestacados pb-5'>
      <Slider {...settings}>
        {itemsDestacados.map((item, index) => (
          <div className='d-flex justify-content-center align-items-center' key={index}>

            <a href={item.mercadolibre} target="_blank" rel="noopener noreferrer" className='text-decoration-none w-100'>
              <div className='destacados'>
                <img height={'100%'} width={'100%'} src={item.src} alt={item.nombre} />
              </div>
              <h3 className='mb-0 mt-2 destacados'>{item.nombre}</h3>
              {Array.isArray(item.color) && item.color.length > 1 && (
                <p className='destacados mb-0'>Disponible en varios colores</p>
              )}
            </a>
          </div>
        ))}
      </Slider>
      </div>
      )}
      </>
  );
}

export default DestacadosCategoria
