import React from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import Banner from '../Components/Banner';
import BestSellers from '../Components/BestSellers';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Location from '../Components/Location';
import BestSeller from '../Img/BestSellers.svg';
import Mercadolibre from '../Img/Mercadolibre.svg';
import '../css/Pages/home.css';

function Home({ lenceria, onCategorySelect, categoriaActual }) {
  const navigate = useNavigate();

  const handleCategoryClick = (categoria) => {
    onCategorySelect(categoria);
    navigate(`/${categoria}`);
  };


  return (
    <div>
      <Helmet>
        <title>SomosDeCompras</title>
      </Helmet>
      <Header onCategorySelect={onCategorySelect} handleCategoryClick={handleCategoryClick} />

      <div className='container backgroundgrey p-md-0'>

        <div className="row envios">
          <div className='col-12 d-flex flex-column flex-lg-row flex-lg-wrap justify-content-around align-items-center'>
            <p className='d-none d-lg-block py-2 py-lg-0 mb-0'>PAGOS SEGUROS</p>
            <p className='d-none d-lg-block py-2 py-lg-0 mb-0'>ENVIOS <span>A DOMICILIO</span></p>
            <img className='py-2 py-lg-0' src={Mercadolibre} alt="Mercado libre" />
            <p className='d-none d-lg-block py-2 py-lg-0 mb-0'><span>ENTREGA RÁPIDA</span></p>
            <p className='d-none d-lg-block py-2 py-lg-0 mb-0'>PAGO EN <span>CUOTAS</span></p>
          </div>
        </div>

        <Banner handleCategoryClick={handleCategoryClick}/>

        <div>
          <img className='mt-5 mb-5' src={BestSeller} alt="Best Sellers" />
          <BestSellers lenceria={lenceria} />
        </div>

        {/* <Categorias handleCategoryClick={handleCategoryClick} /> */}
    
        <Location />

      </div>

      <Footer />
    </div>
  )
}

export default Home